import React from "react";
import {Modal, ModalBody} from "reactstrap";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import PosterImage from "./images/poster.jpg"
import "./styles.css";

const VideoDemo = () => {
	const [isModelOpened, toggleModal] = React.useState(false);
  const data = useStaticQuery(graphql`
    query {
      playButton: file(relativePath: { eq: "play-button.png" }) {
        childImageSharp {
          fluid(maxWidth: 64) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="video-demo-section">
      <div className="macbook-custom">
        <div className="screen-custom">
          <div className="viewport-custom">
          </div>
          <div className="icon-play" onClick={() => toggleModal(true)} onKeyDown={() => toggleModal(true)} role="button" tabIndex={-1}>
            <Img fluid={data.playButton.childImageSharp.fluid} alt={'Play'} />
          </div>
        </div>
        <div className="base-custom"/>
        <div className="notch-custom"/>
      </div>

      {isModelOpened ? (
        <Modal size="xl" isOpen={isModelOpened} centered={true} toggle={() => toggleModal(false)} className="video-modal">
          <ModalBody>
            <div className="video-wrapper">
              <video autoPlay={true} className="video-js" controls preload="none" poster={PosterImage}>
                <source src="https://s3.eu-west-2.amazonaws.com/assets.mockless.com/mockless.mp4" type="video/mp4" />
                <p className="no-js">
                  To view this video please enable JavaScript and consider upgrading to a web browser that
                </p>
              </video>
            </div>
          </ModalBody>
        </Modal>
      ) : null}
    </div>
  );
};

export default VideoDemo;

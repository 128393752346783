import React from "react";
import Container from "../../container/Container";
import { Row, Col } from "reactstrap";
import { Link } from "gatsby";
import Title from "../../../components/typography/Title";
import Subtitle from "../../../components/typography/Subtitle";
import "./styles.css";
import ButtonPrimary from "../../buttons/ButtonPrimary"

const Heading = ({
  title,
  subtitle,
  children,
}) => {
  return (
    <>
      <header className="primary-header">
        <Container size="xlarge">
          <Row>
            <Col lg="6" xlg="12">
              <Title
                size="large"
                invertColor={true}
              >{ title }</Title>

              <Subtitle
                size="medium"
                invertColor={true}>
                { subtitle }
              </Subtitle>

              <div className="mt-5 mb-3 text-center text-lg-left">
                <ButtonPrimary>
                  <Link
                    href={`${process.env.REACT_APP_DASHBOARD_URL}/register`}
                  >
                    Start now
                    <div className="inner">
                      No credit card required!
                    </div>
                  </Link>
                </ButtonPrimary>
              </div>
            </Col>
            <Col xs="12" lg="6">
              { children }
            </Col>
          </Row>
        </Container>
      </header>
    </>
  );
};

export default Heading;

import React from "react";
import Container from "../container/Container";
import "./style.css";
import Title from "../typography/Title"
import Subtitle from "../typography/Subtitle"
import Description from "../typography/Description"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const highlitedFeatures = [
	{
		title: 'Zero configurations',
		image: 'webpack',
		imageAlt: 'Webpack',
		description: 'Focus on business logic, not on project or server setup',
	},
	{
		title: 'One-click setup',
		image: 'docker',
		imageAlt: 'Docker',
		description: 'Run your project anywhere by executing a single command',
	},
	{
		title: 'Highly scalable',
		image: 'aws',
		imageAlt: 'Aws',
		description: 'Deploy your project on AWS within a minute',
	},
	{
		title: 'Stay always up to date',
		image: 'node',
		imageAlt: 'Node.js',
		description: 'Upgrade the peer dependencies with a press of a button',
	},
];

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      webpack: file(relativePath: { eq: "brands/webpack.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      aws: file(relativePath: { eq: "brands/aws.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      node: file(relativePath: { eq: "brands/node.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      docker: file(relativePath: { eq: "brands/docker.png" }) {
        childImageSharp {
          fluid(maxWidth: 120) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `);

  return (
    <div className="why-us-section">
      <Container size="xl3">
        <div className="title-wrapper">
          <Title
            size="large"
            align="text-center"
            invertColor={true}
          >
            The power of low-code development
          </Title>
          <Subtitle
            align="text-center"
            invertColor={true}
            size="large"
          >
            Get the speed of a no-code platform and the power of a strong development team at a fraction of the costs.
          </Subtitle>
        </div>

        <div className="features-wrapper">
          {
            highlitedFeatures.map(({
              title,
              image,
              imageAlt,
              description,
            }) => (
              <div className="feature-box" key={title}>
                <Img fluid={data[image].childImageSharp.fluid} alt={imageAlt} title={title} />
                <Title
                  size="small"
                  align="text-center"
                >
                  {title}
                </Title>
                <Description
                  size="medium"
                  align="text-center"
                >
                  {description}
                </Description>
              </div>
            ))
          }
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;

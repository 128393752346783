import * as React from 'react';
import classNames from 'classnames';
import "./styles.css";

export default function({
  size = 'normal',
  invertColor,
  children,
}) {
  return <p className={classNames('description-component', size, {
    'invert-color': invertColor,
  })}>{children}</p>;
}

import React from "react";
import { Row, Col } from "reactstrap";
import MoreInfoButton from "../buttons/MoreInfoButton";
import Container from '../container/Container';
import Title from "../typography/Title";
import Description from "../typography/Description";
import "./style.css";
import Browser from "../browser/Browser"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query {
      git: file(relativePath: { eq: "screens/app/git.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 798) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div className="about-us-section">
      <Container size="xl3">
        <Row>
          <Col xs="12" lg="6" className="order-lg-first">
            <div className="section-heading powerful-features text-center text-lg-left mb-4 mb-lg-5">
              <Title>
                Buy time for your startup to succeed faster.
              </Title>
            </div>
            <div className="section-heading">
              <Description size="large">
                Your development team can be now focused on building the business logic only and Mockless will help them create any common functionality.
              </Description>

              <Description size="large">
                With Mockless you have full control over the source code and you can review anytime the changes it has made.
              </Description>

              <div className="d-block d-lg-none">
                <figure className="rounded overflow-hidden shadow">
                  <Img fluid={data.git.childImageSharp.fluid} alt="Git" title="Git" />
                </figure>
              </div>

              <MoreInfoButton
                className="btn btn-primary mt-4"
                text="Book a live demo"
                to="https://calendly.com/mockless/demo"
                target="_blank"
                rel="noopener noreferrer nofollow"
              />
            </div>
          </Col>

          <Col lg="6" className="d-none d-lg-block">
            <Browser>
              <Img fluid={data.git.childImageSharp.fluid} alt="Git" title="Git" />
            </Browser>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUs;

import React from "react";
import Container from "../container/Container";
import Title from "../typography/Title"
import Subtitle from "../typography/Subtitle"
import Description from "../typography/Description"
import "./styles.css";

const cards = [
  {
    title: 'Scalable',
    description: 'Build systems that are easy to scale on cloud-native architecture.',
    icon: 'cloud',
  },
  {
    title: 'Customizable',
    description: 'Extend the code base with no limits directly into your preferred IDE.',
    icon: 'customizable',
  },
  {
    title: 'Maintainable',
    description: 'Have always the latest tech stacks, best practices and updates.',
    icon: 'maintanable',
  },
  {
    title: 'Time-effective',
    description: 'Respond quickly to changing customer needs during product-market fit.',
    icon: 'time',
  },
  {
    title: 'Cost-effective',
    description: 'Have predictable costs, pay extra only for custom features.',
    icon: 'cost',
  },
  {
    title: 'Controllable',
    description: "Platform independent, your business is not impacted by other 3rd parties.",
    icon: 'controllable',
  },
];

const AboutUs = () => {
  return (
  	<>
      <div className="key-differentiators-section">
        <Container size="xl2">
          <div className="title-wrapper">
            <Title
              size="large"
              align="text-center"
            >
              The value of low-code automation
            </Title>
            <Subtitle
              align="text-center"
              size="large"
            >
              Our platform automates every step of your application lifecycle to enable rapid delivery of a variety of software use cases.
            </Subtitle>
          </div>
          <div className="content">
            {
              cards.map(({title, description, icon}) => (
                <div className="group">
                  <div className="description">
                    <div className="illustration">
                      <img src={require(`./images/${icon}.svg`)} alt={icon} width="48px" height="48px" />
                    </div>

                    <div className="text">
                      <Title
                        size="xsmall"
                      >
                        {title}
                      </Title>
                      <Description
                        size="small"
                      >
                        {description}
                      </Description>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </Container>
      </div>
	  </>
  );
};

export default AboutUs;

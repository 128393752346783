import React from "react";
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./styles.css";

const MoreInfoButton = props => {
  return (
    <Button href={props.to} target={props.target} rel={props.rel} className={`primary-button ${props.className}`}>
      {props.text}
      <FontAwesomeIcon
        icon={["fas", "long-arrow-alt-right"]}
        className="animated-icon"
      />
    </Button>
  );
};

export default MoreInfoButton;

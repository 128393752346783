import React from "react";
import { Row, Col, Nav, NavItem } from "reactstrap";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image";
import Container from "../container/Container";
import "./styles.css";

const renderPartners = (data) => {
  let partners = [];

  for (let i = 1; i <= 5; ++i) {
    partners.push(
      <NavItem key={i}>
        <Img fluid={ data[`p${i}`].childImageSharp.fluid } alt="" title="" />
      </NavItem>
    );
  }

  return partners;
};

const Partners = () => {
  const data = useStaticQuery(graphql`
    query {
      p1: file(relativePath: { eq: "trusted-by/1.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      p2: file(relativePath: { eq: "trusted-by/2.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      p3: file(relativePath: { eq: "trusted-by/3.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      p4: file(relativePath: { eq: "trusted-by/4.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      p5: file(relativePath: { eq: "trusted-by/5.png" }) {
        childImageSharp {
          fluid(maxWidth: 160) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  return (
    <div className="partners">
      <Container size="small">
        <Row>
          <Col md="12">
            <Nav>{renderPartners(data)}</Nav>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Partners;

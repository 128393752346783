import React from "react"
import SEO from "../components/seo"
import Header from "../components/headers/primary/Primary";
import Navigator from "../components/navigator/DefaultNavbar";
import Partners from "../components/partners/Partners";
import AboutUs from "../components/aboutUs/AboutUs";
import WhyMockless from "../components/whyMockless/WhyMockless";
import KeyDifferentiators from "../components/keyDifferentiators/KeyDifferentiators";
import KeyFeatures from "../components/keyFeatures/KeyFeatures";
import Footer from "../components/footer/DefaultFooter";
import VideoDemo from "../components/videoDemo/VideoDemo";
import CookieConsent from "../components/cookieConsent/CookieConsent";

const IndexPage = () => (
  <>
    <SEO
      title="Low-code platform for building APIs"
      description="Create your backend infrastructure up to 80% faster. Automatically create the project boilerplate in Node.js with TypeScript and deploy it to AWS within a few minutes." />
    <Navigator
      useSpaNav={true}
    />
    <Header
      title="Validate your product ideas faster, at minimal costs."
      subtitle={
        <>
          Create your backend infrastructure up to <span className="highliter">80% faster</span>. < br/>
          Stay focused on what makes your business unique and automate the rest with Mockless.
        </>
      }
    >
      <VideoDemo />
    </Header>
    <Partners />
    <AboutUs />
    <WhyMockless />
    <KeyDifferentiators />
    <KeyFeatures />
    <Footer />
    <CookieConsent />
  </>
)

export default IndexPage

import * as React from 'react';
import classNames from 'classnames';
import "./style.css";

export default function ({
  children,
  position = 'left',
  rightTopImage,
  bottomLeftImage,
}) {
  return (
    <div className={classNames("absolute", "browser-wrapper", {
      [position]: true,
    })}>
      <div className="browser shadow-lg">
        { children }
        <div className="notch" />
      </div>

      { rightTopImage ? rightTopImage : null }
      { bottomLeftImage ? bottomLeftImage : null }
    </div>
  );
}

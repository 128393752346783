import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classname from 'classnames';
import {Zoom, Fade} from "react-reveal";
import Tilt from "react-tilt";
import Container from "../container/Container";
import "./styles.css";
import Description from "../typography/Description"
import Title from "../typography/Title"
import Img from "gatsby-image"
import Browser from "../browser/Browser"
import { graphql, useStaticQuery } from "gatsby"

const featureTabs = [
	{
		id: 'data-model',
		title: 'Data model',
		description: 'Build the data model structure in a visual editor',
		icon: 'database',
		images: {
			'main': 'entities',
			'right-top': 'propertyConfig',
			'bottom-left': 'validations',
		},
		alts: {
			'main': 'manage entities',
			'right-top': 'configure properties',
			'bottom-left': 'add entity validators',
		},
	},
	{
		id: 'api-endpoints',
		title: 'API endpoints',
		description: 'Give access to the data model in just a few clicks',
		icon: 'layer-group',
		images: {
			'main': 'routers',
			'bottom-left': 'addRouter',
		},
		alts: {
			'main': 'create REST endpoints',
			'bottom-left': 'add REST API',
		},
	},
	{
		id: 'functional-flows',
		title: 'Functional flow',
		description: 'Create advanced authorization flows',
		icon: 'retweet',
		images: {
			'main': 'flows',
			'bottom-left': 'flowConfig',
		},
		alts: {
			'main': 'manage api flows',
			'bottom-left': 'configure api floes',
		},
	},
	{
		id: 'source-code',
		title: 'Source code',
		description: 'Compare the source code changes between builds',
		icon: 'code',
		images: {
			'main': 'sourceCode',
			'right-top': 'sourceCodeDiff',
			'bottom-left': 'sourceCodeType',
		},
		alts: {
			'main': 'view git source',
			'right-top': 'source code diff',
			'bottom-left': 'source code type',
		},
	},
	{
		id: 'business-logic',
		title: 'Business logic',
		description: 'Use your IDE to make any changes to the source code',
		icon: 'edit',
		images: {
			'main': 'businessLogic',
			'right-top': 'businessLogicBranch',
			'bottom-left': 'businessLogicConfig',
		},
		alts: {
			'main': 'custom business logic',
			'right-top': 'select git branch',
			'bottom-left': 'configure git repository',
		},
	},
	{
		id: 'environment-deploy',
		title: 'Environment deploy',
		description: 'Set up environments on AWS or Docker in a few clicks',
		icon: 'cloud',
		images: {
			'main': 'environmentDeploy',
		},
		alts: {
			'main': 'docker as a service',
		},
	},
];

const AboutUs = () => {
	const [activeFeatureTab, setActiveFeaturetab] = React.useState('data-model');
  const data = useStaticQuery(graphql`
    query {
      entities: file(relativePath: { eq: "screens/app/entities.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1142) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      routers: file(relativePath: { eq: "screens/app/routers.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1137) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      flows: file(relativePath: { eq: "screens/app/flows.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1134) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sourceCode: file(relativePath: { eq: "screens/app/source-code.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1129) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      businessLogic: file(relativePath: { eq: "screens/app/business-logic.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1143) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      environmentDeploy: file(relativePath: { eq: "screens/app/environment-deploy.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1143) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      addRouter: file(relativePath: { eq: "features/add-router.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 628) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      businessLogicBranch: file(relativePath: { eq: "features/business-logic-branch.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 495) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      businessLogicConfig: file(relativePath: { eq: "features/business-logic-config.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 497) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      flowConfig: file(relativePath: { eq: "features/flow-config2.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      propertyConfig: file(relativePath: { eq: "features/property-config.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 625) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sourceCodeDiff: file(relativePath: { eq: "features/source-code-diff.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 654) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      sourceCodeType: file(relativePath: { eq: "features/source-code-type.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 498) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      validations: file(relativePath: { eq: "features/validations.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 648) {
            ...GatsbyImageSharpFluid
          }
        }
      },
    }
  `)

  return (
    <div className="key-features-section">
      <Container size="large">
        <div className="content">
          <div className="features-tabs-wrapper">
            { featureTabs.map(({
              id,
              title,
              description,
              icon,
            }) => (
              <div className={ classname("feature-tab", {
                'active': activeFeatureTab === id,
              }) } onClick={() => setActiveFeaturetab(id)} onKeyDown={() => setActiveFeaturetab(id)} key={id}>
                <div className="feature-tab-icon">
                  <FontAwesomeIcon icon={["fa", icon]} />
                </div>
                <div className="feature-tab-content">
                  <Title
                    size="xsmall"
                  >
                    {title}
                  </Title>
                  <Description
                    size="medium"
                    align="text-center"
                  >
                    {description}
                  </Description>
                </div>
              </div>
            )) }
          </div>
          <div className="features-tabs-illustration">
            { featureTabs.filter(({id}) => id === activeFeatureTab).map(({
              id,
              title,
              description,
              images,
              alts,
            }) => (
              <div className="feature-page develop-data-model" key={id}>
                <div className="feature-tab-content">
                  <Title
                    size="xsmall"
                  >
                    {title}
                  </Title>
                  <Description
                    size="medium"
                    align="text-center"
                  >
                    {description}
                  </Description>
                </div>

                <Browser
                  rightTopImage={
                    data[images['right-top']] ? <div className="highliter-image-top">
                      <Fade right>
                        <Tilt options={{ max: 20, glare: true, maxGlare: 0.4 }}>
                          <figure className="rounded overflow-hidden shadow">
                            <Img fluid={data[images['right-top']].childImageSharp.fluid} alt={alts['right-top']} title={title} />
                          </figure>
                        </Tilt>
                      </Fade>
                    </div> : null
                  }
                  bottomLeftImage={
                    data[images['bottom-left']] ? <div className="highliter-image-bottom">
                      <Zoom bottom>
                        <Tilt options={{ max: 20, glare: true, maxGlare: 0.4 }}>
                          <figure className="rounded overflow-hidden shadow">
                            <Img fluid={data[images['bottom-left']].childImageSharp.fluid} alt={alts['bottom-left']} title={title} />
                          </figure>
                        </Tilt>
                      </Zoom>
                    </div> : null
                  }>
                  <Img fluid={data[images.main].childImageSharp.fluid} alt={alts['main']} title={title} />
                </Browser>
              </div>
            )) }
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutUs;

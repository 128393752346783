import * as React from 'react';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import "./styles.css";

export default function ({
  children,
}) {
  return (
    <Button className={classNames('primary-button', 'xsmall')}>
      {children}
    </Button>
  );
}
